.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* First, target the specific label for "Name" */
.amplify-label[for="amplify-id-:r5u:"] {
  /* Visually hide the original label text */
  font-size: 0;
  color: transparent;
}

.amplify-label[for="amplify-id-:r5u:"]::after {
  content: "Company";
  font-size: 16px;
  color: black;
}

/* Hide the original 'Nickname' label */
form .amplify-flex div:nth-of-type(6) > label.amplify-label {
  visibility: hidden;
  position: relative;
}

/* Add a 'Company' label using a pseudo-element */
form .amplify-flex div:nth-of-type(6) > label.amplify-label::after {
  content: 'Company';
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
}
